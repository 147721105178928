import {
  Box,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuItemOption,
  MenuList,
  MenuOptionGroup
} from "@chakra-ui/react";
import { faEllipsisV, faRemove } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ReactNode, useState } from "react";
import ContentBox from "../ContentBox";

const MetricsTile = ({
  title,
  subtitle,
  help,
  children,
  hide,
  optionGroups
}: {
  title: string;
  subtitle?: ReactNode;
  help?: string;
  children: ReactNode;
  hide?: () => void;
  optionGroups?: {
    title: string;
    type: "checkbox" | "radio";
    selectedValue: string;
    items: { key: string; title: string; action: () => void }[];
  }[];
}) => {
  const [showRemoveTileMenu, setShowRemoveTileMenu] = useState<string | null>();
  return (
    <Box
      onMouseEnter={() => {
        setShowRemoveTileMenu("statsIngestApiRequests");
      }}
      onMouseLeave={() => {
        setShowRemoveTileMenu(null);
      }}
      height={"100%"}
    >
      <ContentBox
        title={title}
        subTitle={subtitle}
        help={help}
        fullHeight={true}
        childrenMt={16}
        headerRight={
          <Box
            style={{
              opacity: showRemoveTileMenu ? 1 : 0,
              transition: "opacity 0.25s"
            }}
          >
            <Menu>
              <MenuButton
                as={IconButton}
                icon={<Icon as={FontAwesomeIcon} icon={faEllipsisV}></Icon>}
                variant={"ghost"}
              ></MenuButton>
              <MenuList>
                {hide && (
                  <MenuItem
                    icon={<Icon as={FontAwesomeIcon} icon={faRemove}></Icon>}
                    onClick={() => hide()}
                  >
                    Hide
                  </MenuItem>
                )}
                {optionGroups?.map((optionGroup) => (
                  <>
                    <MenuDivider />
                    <MenuOptionGroup
                      title={optionGroup.title}
                      type={optionGroup.type}
                      defaultValue={optionGroup.selectedValue}
                    >
                      {optionGroup.items.map(({ key, title, action }) => (
                        <MenuItemOption key={key} onClick={action} value={key}>
                          {title}
                        </MenuItemOption>
                      ))}
                    </MenuOptionGroup>
                  </>
                ))}
              </MenuList>
            </Menu>
          </Box>
        }
      >
        {children}
      </ContentBox>
    </Box>
  );
};

export default MetricsTile;
