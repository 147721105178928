import "reflect-metadata";

import {
  Box,
  Button,
  Grid,
  GridItem,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuGroup,
  MenuItem,
  MenuList,
  Progress,
  Text
} from "@chakra-ui/react";
import { faEllipsisV } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useFlags } from "launchdarkly-react-client-sdk";
import { NavLink } from "react-router-dom";
import { useShallow } from "zustand/react/shallow";
import ContentBox from "./components/ContentBox";
import MetaTitle from "./components/MetaTitle";
import DeliveryApiMetrics from "./components/Tiles/DeliveryApiMetricsTile";
import DontGetStuckTile from "./components/Tiles/DontGetStuckTile";
import NewsTile from "./components/Tiles/NewsTile";
import StatsDataStorageTile from "./components/Tiles/StatsDataStorageTile";
import StatsDeliveryApiRequestsTile from "./components/Tiles/StatsDeliveryApiRequestsTile";
import StatsIngestApiRequestsTile from "./components/Tiles/StatsIngestApiRequestsTile";
import StatsQueueSizeTile from "./components/Tiles/StatsQueueSizeTile";
import StatsSourceEntitiesTile from "./components/Tiles/StatsSourceEntitiesTile";
import { useInvites } from "./features/account/api/getInvites";
import { useAuthStore } from "./features/auth/store";
import { useEnvironments } from "./features/environments/api/getEnvironments";
import PlatformHealthTile from "./features/feedback/components/TenantHealth";
import GettingStartedOverview from "./features/getting-started/GettingStartedOverview";
import useDeliveryRequestsMetrics from "./features/metrics/api/useDeliveryRequestsMetrics";
import useEnterspeedDeliveryApiResponseTimeMetrics from "./features/metrics/api/useEnterspeedDeliveryApiResponseTimeMetrics";
import useEnterspeedDeliveryApiSlaMetrics from "./features/metrics/api/useEnterspeedDeliveryApiSlaMetrics";
import useIngestRequestsMetrics from "./features/metrics/api/useIngestRequestsMetrics";
import useQueueJobsMetrics from "./features/metrics/api/useQueuedJobsMetrics";
import useSourceEntitiesMetrics from "./features/metrics/api/useSourceEntitiesMetrics";
import useHomeStore from "./useHomeStore";

export default function Home() {
  const user = useAuthStore((state) => state.user);

  const { data: invitesData, isRefetching } = useInvites();

  const sourceEntitiesMetrics = useSourceEntitiesMetrics();
  const deliveryRequestsMetrics = useDeliveryRequestsMetrics();
  const ingestRequestMetrics = useIngestRequestsMetrics();
  const queuedJobs = useQueueJobsMetrics();
  const deliveryApiResponseTimeMetrics =
    useEnterspeedDeliveryApiResponseTimeMetrics();
  const deliveryApiSlaMetrics = useEnterspeedDeliveryApiSlaMetrics();
  const environments = useEnvironments();

  const flags = useFlags();

  const errorTile = flags.errorTile as boolean;

  const [removeTile, showTile, removedTiles] = useHomeStore()(
    useShallow((state) => [
      state.removeTile,
      state.showTile,
      state.removedTiles
    ])
  );

  const tileShortNames = [
    { key: "statsSourceEntities", name: "Source entities" },
    { key: "statsDeliveryApiRequests", name: "Delivery requests" },
    { key: "statsDataStorage", name: "Data storage" },
    { key: "statsQueueSize", name: "Queued jobs" },
    { key: "deliveryApiMetrics", name: "Delivery API" },
    { key: "news", name: "Feature news" },
    { key: "gettingStarted", name: "Getting started" },
    { key: "dontGetStuck", name: "Don't get stuck" },
    { key: "tenantHealth", name: "Tenant health" },
    {
      key: "statsIngestApiRequests",
      name: "Ingest API requests"
    }
  ];

  return (
    <>
      <MetaTitle title="Overview" />
      <Box
        width={"100%"}
        sx={{
          containerType: "inline-size",
          containerName: "grid-container",
          "@container grid-container (min-width: 0px)": {
            ".dashboard-grid": {
              gridTemplateColumns: "repeat(1, minmax(0,1fr))"
            },
            ".col1": { gridColumn: "span 1" },
            ".col1-2": { gridColumn: "span 1" },
            ".col2": { gridColumn: "span 1" },
            ".col3": { gridColumn: "span 1" },
            ".col4": { gridColumn: "span 1" },
            ".col5": { gridColumn: "span 1" }
          },
          "@container grid-container (min-width: 432px)": {
            ".dashboard-grid": {
              gridTemplateColumns: "repeat(2, minmax(0,1fr))"
            },
            ".col1": { gridColumn: "span 1" },
            ".col1-2": { gridColumn: "span 2" },
            ".col2": { gridColumn: "span 2" },
            ".col3": { gridColumn: "span 2" },
            ".col4": { gridColumn: "span 2" },
            ".col5": { gridColumn: "span 2" }
          },
          "@container grid-container (min-width: 1200px)": {
            ".dashboard-grid": {
              gridTemplateColumns: "repeat(4, minmax(0,1fr))"
            },
            ".col1": { gridColumn: "span 1" },
            ".col1-2": { gridColumn: "span 1" },
            ".col2": { gridColumn: "span 2" },
            ".col3": { gridColumn: "span 3" },
            ".col4": { gridColumn: "span 4" },
            ".col5": { gridColumn: "span 4" }
          },
          "@container grid-container (min-width: 2250px)": {
            ".dashboard-grid": {
              gridTemplateColumns: "repeat(5, minmax(0,1fr))"
            },
            ".col1": { gridColumn: "span 1" },
            ".col1-2": { gridColumn: "span 1" },
            ".col2": { gridColumn: "span 2" },
            ".col3": { gridColumn: "span 3" },
            ".col4": { gridColumn: "span 4" },
            ".col5": { gridColumn: "span 5" }
          }
        }}
      >
        <Grid
          width="100%"
          gap={4}
          grid-auto-flow="column"
          className="dashboard-grid"
        >
          <GridItem className="col5">
            <Box display={"flex"} justifyContent={"space-between"}>
              <Text fontSize="24px" fontWeight="semibold">{`👋 Hi ${
                user?.firstName ?? ""
              }, welcome to Enterspeed`}</Text>{" "}
              <Menu>
                <MenuButton
                  as={IconButton}
                  icon={<Icon as={FontAwesomeIcon} icon={faEllipsisV}></Icon>}
                  variant={"ghost"}
                ></MenuButton>
                <MenuList>
                  <MenuGroup title="Add tiles">
                    {removedTiles.map((removedTile) => {
                      return (
                        <MenuItem
                          key={`removedTile-${removedTile}`}
                          onClick={() => showTile(removedTile)}
                        >
                          {tileShortNames.find(
                            (element) => element.key === removedTile
                          )?.name ?? removedTile}
                        </MenuItem>
                      );
                    })}
                    {removedTiles.length === 0 && (
                      <Text ml={3} color={"gray.400"}>
                        - All tiles are already added
                      </Text>
                    )}
                  </MenuGroup>
                </MenuList>
              </Menu>
            </Box>
          </GridItem>
          {invitesData && invitesData.length !== 0 && (
            <GridItem className="col5">
              <ContentBox title="Pending invitations">
                <Text>
                  {`You have ${invitesData.length} pending ${
                    invitesData.length > 1 ? "invitations" : "invitation"
                  } `}
                  <Button
                    as={NavLink}
                    variant={"link"}
                    to="/account/invitations"
                  >
                    Go to invitation page
                  </Button>
                  {" to join an existing tenant."}
                </Text>
              </ContentBox>
              <Box>
                {isRefetching && <Progress size="xs" isIndeterminate />}
              </Box>
            </GridItem>
          )}
          {user?.multipleEmailsWarning && (
            <GridItem className="col5">
              <ContentBox title="⚠️ Duplicate user email">
                <Text>
                  We've noticed multiple users registered with the same email
                  address ({user?.email}). This can happen if you have both an
                  email-and-password user and a Microsoft Entra ID user with the
                  same email.
                </Text>
                <Text>
                  If you can't find your tenants when signing in with your Entra
                  ID (because, let's face it, technology can be tricky), try
                  signing in with your username and password instead. Feel free
                  to reach out to support@enterspeed.com, via chat, or your
                  support Slack channel, and let us know your preferred sign-in
                  method. We'll have you sorted in no time!
                </Text>
              </ContentBox>
            </GridItem>
          )}
          {!removedTiles.includes("statsIngestApiRequests") && (
            <GridItem className="col1">
              <StatsIngestApiRequestsTile
                ingestStats={ingestRequestMetrics.data}
                hide={() => removeTile("statsIngestApiRequests")}
              />
            </GridItem>
          )}
          {!removedTiles.includes("statsSourceEntities") && (
            <GridItem className="col1">
              <StatsSourceEntitiesTile
                sourceEntitiesMetrics={sourceEntitiesMetrics.data}
                hide={() => removeTile("statsSourceEntities")}
              />
            </GridItem>
          )}
          {!removedTiles.includes("statsDeliveryApiRequests") && (
            <GridItem className="col1">
              <StatsDeliveryApiRequestsTile
                deliveryStats={deliveryRequestsMetrics.data}
                hide={() => removeTile("statsDeliveryApiRequests")}
              />
            </GridItem>
          )}

          {!removedTiles.includes("statsDataStorage") && (
            <GridItem className="col1">
              <StatsDataStorageTile
                sourceEntityMetrics={sourceEntitiesMetrics.data}
                hide={() => removeTile("statsDataStorage")}
              />
            </GridItem>
          )}
          {!removedTiles.includes("statsQueueSize") && (
            <GridItem className="col1">
              <StatsQueueSizeTile
                queuedJobsDto={queuedJobs.data}
                environments={environments.data}
                hide={() => removeTile("statsQueueSize")}
              />
            </GridItem>
          )}
          {!removedTiles.includes("news") && (
            <GridItem className="col3">
              <NewsTile hide={() => removeTile("news")} />
            </GridItem>
          )}
          {errorTile && !removedTiles.includes("tenantHealth") && (
            <GridItem className="col2">
              <PlatformHealthTile hide={() => removeTile("tenantHealth")} />
            </GridItem>
          )}
          {!removedTiles.includes("deliveryApiMetrics") && (
            <GridItem className="col1-2">
              <DeliveryApiMetrics
                responseTimes={deliveryApiResponseTimeMetrics.data}
                sla={deliveryApiSlaMetrics.data}
                hide={() => removeTile("deliveryApiMetrics")}
              />
            </GridItem>
          )}
          {!removedTiles.includes("gettingStarted") && (
            <GridItem className="col1">
              <GettingStartedOverview
                hide={() => removeTile("gettingStarted")}
              />
            </GridItem>
          )}
          {!removedTiles.includes("dontGetStuck") && (
            <GridItem className="col1">
              <DontGetStuckTile hide={() => removeTile("dontGetStuck")} />
            </GridItem>
          )}
        </Grid>
      </Box>
    </>
  );
}
