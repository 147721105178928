import { useColorModeValue } from "@chakra-ui/react";
import { getChakraColorAsHex } from "../../constants/colors";

export const getMonthlyProjection = (usage: number) => {
  if (usage === 0) {
    return 0;
  }

  const now = new Date(Date.now());

  const dayPassedInCurrentMonth = now.getDate();
  const daysInMonth = new Date(
    now.getFullYear(),
    now.getMonth() + 1,
    0
  ).getDate();
  return (usage / dayPassedInCurrentMonth) * daysInMonth;
};

export const alertZone = [0.9, 1];
export const overrunZone = [1, 1.05];

export const useGaugeColors = ({
  limit,
  usage,
  lowerBoundAlert
}: {
  limit?: number;
  usage: number;
  lowerBoundAlert?: number;
}) => {
  const limitExceededBarColor = useColorModeValue(
    getChakraColorAsHex("red.500"),
    getChakraColorAsHex("red.500")
  );

  const orangeBarColor = useColorModeValue(
    getChakraColorAsHex("orange.400"),
    getChakraColorAsHex("orange.300")
  );
  const blueBarColor = useColorModeValue(
    getChakraColorAsHex("brand.500"),
    getChakraColorAsHex("brand.500")
  );

  const projectionBarColor = useColorModeValue(
    getChakraColorAsHex("gray.400"),
    getChakraColorAsHex("gray.900")
  );

  const limitExceededColor = useColorModeValue("black", "white");
  const thresholdColor = useColorModeValue(
    getChakraColorAsHex("brand.500"),
    getChakraColorAsHex("brand.500")
  );

  const thresholdExceededColor = useColorModeValue(
    getChakraColorAsHex("red.800"),
    getChakraColorAsHex("red.800")
  );

  const bgColor = useColorModeValue(
    getChakraColorAsHex("brand.50"),
    getChakraColorAsHex("gray.700")
  );
  const numberColor = useColorModeValue("black", "white");

  const colors = {
    projectionBarColor,
    numberColor,
    limitExceededColor,
    thresholdColor:
      limit && usage < limit ? thresholdColor : thresholdExceededColor,
    bgColor
  };

  const lowerBoundAlertValue = lowerBoundAlert ?? alertZone[0];
  if (!limit || usage < limit * lowerBoundAlertValue) {
    return { ...colors, barColor: blueBarColor };
  }
  return {
    ...colors,
    barColor: usage < limit ? orangeBarColor : limitExceededBarColor
  };
};
