import { useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { useShallow } from "zustand/react/shallow";
import useMixPanel from "../../../mixpanel/useMixPanel";
import { useAuthStore } from "../../auth/store";
import { useTenantStore } from "../store";
import { ITenant } from "../types";

const useTenantSwitcher = () => {
  const userState = useAuthStore();
  const { activeTenant, setActiveTenant, availableTenants } = useTenantStore(
    useShallow(({ activeTenant, setActiveTenant, availableTenants }) => ({
      activeTenant,
      setActiveTenant,
      availableTenants
    }))
  );
  const mixpanel = useMixPanel();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  return {
    switch: (
      tenant: ITenant,
      { redirectToHome }: { redirectToHome: boolean } = {
        redirectToHome: true
      }
    ) => {
      mixpanel.track("tenantSwitch", {
        from: activeTenant,
        to: tenant
      });

      setActiveTenant(userState, tenant, true);
      queryClient.clear();
      if (redirectToHome) {
        return navigate("/");
      }
    },
    switchToPreviousAvailableTenant() {
      const tenantId = localStorage.getItem("enterspeed-previous-tenant-id");
      const tenant = availableTenants.length
        ? availableTenants.find((t) => t.id.idValue === tenantId) ??
          availableTenants[0]
        : undefined;

      if (tenant) {
        setActiveTenant(userState, tenant, true);
      } else {
        setActiveTenant(
          userState,
          {
            id: { tenantGuid: "", idValue: "" },
            name: "",
            isUsingSourceGroups: true,
            isUsingSchemasBulkDeployment: true,
            javascriptSchemaFormatConfig: { enabled: false },
            plan: {
              id: "",
              sourceEntitiesByteLimit: 0,
              sourceEntitiesCountLimit: 0,
              deliveryApiRequestCountLimit: 0,
              ingestApiChangedRequestCountLimit: 0,
              ingestApiTotalRequestCountLimit: 0
            }
          },
          true
        );
      }
      queryClient.clear();
      return navigate("/");
    }
  };
};

export default useTenantSwitcher;
