import { useColorModeValue } from "@chakra-ui/react";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useShallow } from "zustand/react/shallow";
import { IngestRequestsMetricsDto } from "../../features/metrics/metrics.service";
import { useTenantStore } from "../../features/tenants/store";
import { ITenant } from "../../features/tenants/types";
import { getMonthlyProjection, useGaugeColors } from "./gauge";
import MetricsPlot from "./MetricsPlot";
import MetricsTile from "./MetricsTile";
import MetricsTileBody from "./MetricsTileBody";
import MetricsToolTip, { MetricTooltipValue } from "./MetricsToolTip";
import useGaugeData from "./useGaugeData";
import useIngestApiRequestsStore from "./useIngestApiRequestsStore";

const StatsIngestApiRequestsTile = ({
  ingestStats,
  isLoading,
  tenant,
  hide
}: {
  ingestStats?: IngestRequestsMetricsDto;
  isLoading?: boolean;
  tenant?: ITenant;
  hide?: () => void;
}) => {
  const { activeTenant } = useTenantStore(
    useShallow(({ activeTenant }) => ({ activeTenant }))
  );

  const [selectedVersion, selectVersion] = useIngestApiRequestsStore()(
    useShallow((state) => [state.selectedVersion, state.selectVersion])
  );

  const flags = useFlags();

  const { enabled, features } = flags.dashboard as {
    enabled: boolean;
    features: string[];
  };

  const usedTenant = tenant ?? activeTenant;

  const changedLimit = usedTenant.plan?.ingestApiChangedRequestCountLimit;
  const changedRequests = ingestStats?.ingestResults.SuccessIngestResult ?? 0;
  const total =
    ingestStats?.totalRequest ??
    Object.values(ingestStats?.ingestResults ?? {}).reduce(
      (acc, val) => acc + val,
      0
    );
  const totalLimit = usedTenant.plan?.ingestApiTotalRequestCountLimit;

  const totalProjected =
    enabled && features.includes("enableProjections")
      ? getMonthlyProjection(total)
      : undefined;

  const changedProjected =
    enabled && features.includes("enableProjections")
      ? getMonthlyProjection(changedRequests)
      : undefined;

  const changedBarColors = useGaugeColors({
    limit: changedLimit,
    usage: changedRequests
  });

  const displayData =
    selectedVersion === "changed"
      ? { usage: changedRequests, projected: changedProjected }
      : { usage: total, projected: totalProjected };

  const getValueFormat = (value: number): string => {
    return value > 1000 ? ".2~s" : ".3~s";
  };

  const defaultTextColors = useColorModeValue("black", "");
  const collapsedExceedLimitColor = useColorModeValue("red.600", "red.600");
  const collapsedBarColor = useColorModeValue("brand.400", "brand.200");
  const collapsedTotalBarColor =
    totalLimit && total > totalLimit
      ? collapsedExceedLimitColor
      : collapsedBarColor;
  const totalBarColor =
    selectedVersion === "all" ? collapsedTotalBarColor : "brand.500";
  const data = useGaugeData(
    {
      usage: displayData.usage,
      projected: displayData.projected,
      limit: selectedVersion === "changed" ? changedLimit : totalLimit,
      upperBound: changedLimit
    },
    {
      valueFormat: getValueFormat(displayData.usage),
      barColor: totalBarColor
    }
  );

  if (selectedVersion === "all" && data.length === 2 && data[0] && data[1]) {
    const totalData = data[1];

    if (changedProjected) {
      data?.push({
        ...data[0],
        value: changedProjected,
        gauge: {
          bar: {
            color: `${changedBarColors.projectionBarColor}33`,
            line: {
              color: `${changedBarColors.projectionBarColor}33`,
              width: 1
            }
          },
          axis: totalData.gauge?.axis
        }
      });
    }

    data?.push({
      ...totalData,
      mode: "gauge",
      value: changedRequests,
      gauge: {
        bar: {
          color: changedBarColors.barColor,
          line: { color: changedBarColors.barColor, width: 1 }
        },
        axis: {
          ...totalData.gauge?.axis,
          tickformat: getValueFormat(changedRequests),
          tickvals: [changedLimit]
        },
        threshold: changedLimit
          ? {
              value: changedLimit,
              line: {
                width: 4,
                color: changedBarColors.thresholdColor
              }
            }
          : undefined
      }
    });
  }

  const help = `The current month's Ingest API requests ${
    changedLimit ? "and the limit based on the tenant's plan" : ""
  } (updated every ~1 minute)`;

  const dynamicTextColor = (color: string) =>
    selectedVersion === "all" ? color : defaultTextColors;

  const tooltipMetrics: MetricTooltipValue[] = [
    {
      label: "Changed",
      value: changedRequests,
      valueColor: dynamicTextColor(changedBarColors.barColor)
    }
  ];

  if (changedProjected) {
    tooltipMetrics.push({
      label: "Changed Projected",
      value: changedProjected,
      valueColor: dynamicTextColor(changedBarColors.barColor)
    });
  }

  if (changedLimit) {
    tooltipMetrics.push({
      label: "Changed Limit",
      value: changedLimit,
      valueColor: dynamicTextColor(changedBarColors.barColor)
    });
  }

  tooltipMetrics.push({
    label: "Total",
    value: total,
    valueColor: dynamicTextColor(totalBarColor)
  });

  if (totalProjected) {
    tooltipMetrics.push({
      label: "Total Projected",
      value: totalProjected,
      valueColor: dynamicTextColor(totalBarColor)
    });
  }

  if (totalLimit) {
    tooltipMetrics.push({
      label: "Total Limit",
      value: totalLimit,
      valueColor: dynamicTextColor(totalBarColor)
    });
  }

  tooltipMetrics.push({
    label: "Change ratio",
    value: (changedRequests / total) * 100,
    valueColor: defaultTextColors,
    format: "0.[0]",
    suffix: "%"
  });

  const actions = [
    {
      key: "total",
      title: "Total",
      action: () => {
        selectVersion("total");
      }
    },
    {
      key: "changed",
      title: "Changed",
      action: () => {
        selectVersion("changed");
      }
    },
    {
      key: "all",
      title: "All",
      action: () => {
        selectVersion("all");
      }
    }
  ];

  return (
    <MetricsTile
      title={`${
        actions.find((x) => x.key === selectedVersion)?.title ?? ""
      } Ingest requests`}
      help={help}
      hide={hide}
      optionGroups={[
        {
          title: "View Ingest Requests",
          type: "radio",
          selectedValue: selectedVersion,
          items: actions
        }
      ]}
    >
      <MetricsTileBody
        tooltipBody={
          <MetricsToolTip
            title={`Ingest requests for ${new Date(Date.now()).toLocaleString(
              "en-US",
              { month: "long" }
            )}`}
            metrics={tooltipMetrics}
          />
        }
      >
        <MetricsPlot data={data} isLoading={isLoading}></MetricsPlot>
      </MetricsTileBody>
    </MetricsTile>
  );
};

export default StatsIngestApiRequestsTile;
