import {
  Button,
  FocusLock,
  Icon,
  Popover,
  PopoverArrow,
  PopoverContent,
  PopoverTrigger
} from "@chakra-ui/react";
import { faAdd } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  IFilterableFieldOption,
  IFrontendCondition,
  IOperatorSelectOption
} from "../types";
import AddOrEditFilterCondition from "./AddOrEditFilterCondition";

const AddFilterPopover = ({
  filterFields,
  operators,
  filterCondition,
  isOpen,
  onOpen,
  onClose,
  onAddFilter
}: {
  filterFields: IFilterableFieldOption[];
  operators: IOperatorSelectOption[];
  filterCondition: IFrontendCondition | undefined;
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
  onAddFilter: (condition: IFrontendCondition) => void;
}) => {
  return (
    <Popover
      isOpen={isOpen}
      onClose={onClose}
      placement="bottom-start"
      closeOnBlur={false}
      isLazy={true}
    >
      <PopoverTrigger>
        <Button
          variant={"primary"}
          leftIcon={<Icon as={FontAwesomeIcon} icon={faAdd}></Icon>}
          onClick={onOpen}
        >
          Add filter
        </Button>
      </PopoverTrigger>
      <PopoverContent p={4}>
        <FocusLock restoreFocus persistentFocus={false}>
          <PopoverArrow />
        </FocusLock>
        <AddOrEditFilterCondition
          operators={operators}
          filterFields={filterFields}
          condition={filterCondition}
          onClose={onClose}
          onSuccess={onAddFilter}
        />
      </PopoverContent>
    </Popover>
  );
};

export default AddFilterPopover;
