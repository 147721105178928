export const ENVIRONMENT = "environment" as const;
export const ENVIRONMENTS = "environments" as const;
export const ENVIRONMENT_CLIENTS = "environment-clients" as const;
export const DOMAINS = "domains" as const;
export const TENANT_USERS = "tenant-users" as const;
export const MANAGEMENT_CLIENTS = "management-clients" as const;
export const LOGS = "logs" as const;
export const SOURCE_GROUPS = "source-groups" as const;
export const DATA_SOURCES = "data-sources" as const;
export const TENANT_INVITES = "tenant-invites" as const;
export const AUTH = "auth" as const;
export const SETTINGS = "settings" as const;
export const TENANTS = "tenants" as const;
export const INVITES = "invites" as const;
export const SCHEMA = "schema" as const;
export const SCHEMAS = "schemas" as const;
export const DEPLOYMENTS_FROM_ENVIRONMENTS =
  "deployments-from-environments" as const;
export const VIEWS = "views" as const;
export const VIEW = "view" as const;
export const ENTITIES = "entities" as const;
export const ENTITY = "entity" as const;
export const SOURCE_ENTITY_TYPES = "source-entity-types" as const;
export const INSPECTED_ROUTES = "inspected-routes" as const;
export const UNCONFIGURED_HOSTNAMES = "unconfigured-hostnames" as const;
export const USERS = "users" as const;
export const HAS_INGESTED_ANY_ENTITIES = "HAS_INGESTED_ANY_ENTITIES" as const;
export const APPS = "APPS" as const;
export const DESTINATIONS = "DESTINATIONS" as const;
export const DESTINATION_ALIASES = "DESTINATION_ALIASES" as const;
export const ANNOUNCEKITNEWS = "ANNOUNCEKITNEWS" as const;
export const METRICS = "METRICS" as const;
export const DELIVERY_REQUESTS = "DELIVERY_REQUESTS" as const;
export const INGEST_REQUESTS = "INGEST_REQUESTS" as const;
export const SOURCE_ENTITIES = "SOURCE_ENTITIES" as const;
export const QUEUED_JOBS = "QUEUED_JOBS" as const;
export const DELIVERY_API_METRICS = "DELIVERY_API" as const;
export const DELIVERY_API_METRICS_RESPONSE_TIME =
  "DELIVERY_API_RESPONSE_TIME" as const;
export const DELIVERY_API_METRICS_SLA = "DELIVERY_API_SLA" as const;
export const INDEX = "INDEXES" as const;
