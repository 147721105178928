import { PlotData } from "plotly.js";
import { getChakraColorAsHex } from "../../constants/colors";
import { overrunZone, useGaugeColors } from "./gauge";

type Options = {
  valueFormat?: string;
  exponentFormat?: "none" | "e" | "E" | "power" | "SI" | "B";
  suffix?: string;
  tickFormatter?: (value: number) => string;
  barColor?: string;
};

const useGaugeData = (
  {
    usage,
    limit,
    projected,
    upperBound
  }: { usage: number; limit?: number; projected?: number; upperBound?: number },
  options?: Options
): Partial<PlotData>[] => {
  const {
    barColor,
    projectionBarColor,
    numberColor,
    bgColor,
    limitExceededColor,
    thresholdColor
  } = useGaugeColors({ limit, usage });

  const [, upperBoundOverrun] = overrunZone;

  const tickValues = getTickValues(usage, limit, projected);
  const projection: Partial<PlotData> | undefined = projected
    ? {
        type: "indicator",
        mode: "gauge",
        value: projected,
        gauge: {
          bgcolor: bgColor,
          axis: {
            tickvals: [],
            range: [
              null,
              upperBoundOverrun * Math.max(limit ?? upperBound ?? 0, projected)
            ],
            tickformat: `.3~s`
          },

          bar: {
            color: `${projectionBarColor}23`,
            line: { color: projectionBarColor, width: 1 }
          }
        }
      }
    : undefined;

  const data: Partial<PlotData>[] = [];

  const usageData: Partial<PlotData> = {
    domain: { x: [0, 1], y: [0, 1] },
    value: usage,
    type: "indicator",
    mode: usage === 0 && !limit ? "number" : "gauge+number",
    number: {
      font: {
        color: limit && usage > limit ? limitExceededColor : numberColor
      },
      valueformat: options?.valueFormat,
      suffix: options?.suffix
    },
    gauge: {
      bgcolor: projected ? "transparent" : bgColor,
      bar: {
        color: options?.barColor
          ? getChakraColorAsHex(options.barColor)
          : barColor,
        line: { color: barColor, width: 1 }
      },

      axis: {
        range: [
          null,
          Math.max(
            (limit ?? upperBound ?? 0) * upperBoundOverrun,
            limit || projected ? usage : usage * 1.3,
            (projected ?? 0) * upperBoundOverrun
          )
        ],
        tickvals: tickValues,

        tickformat: options?.valueFormat,
        exponentformat: options?.exponentFormat,
        ticksuffix: options?.suffix,
        ticktext: options?.tickFormatter
          ? tickValues.map(options.tickFormatter)
          : undefined
      },
      threshold: {
        value: limit,
        line: {
          width: 4,
          color: thresholdColor
        }
      }
    }
  };

  if (projection) {
    data.push(projection);
  }

  data.push(usageData);

  return data;
};

const getTickValues = (
  usage: number,
  limit?: number,
  projection?: number
): number[] => {
  const stepSize = 1;
  const nTicks = 1 / stepSize;
  const ticks = Array.from({ length: nTicks }, (_, k) => (k + 1) * stepSize);

  return ticks.map((x) => x * Math.max(limit ?? projection ?? 0, usage));
};

export default useGaugeData;
