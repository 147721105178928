import { extendTheme, withDefaultColorScheme } from "@chakra-ui/react";
import type { StyleFunctionProps } from "@chakra-ui/styled-system";
import { mode } from "@chakra-ui/theme-tools";

const theme = extendTheme(
  {
    config: {
      initialColorMode: "system",
      useSystemColorMode: true
    },
    styles: {
      global: (props: StyleFunctionProps) => ({
        body: {
          bg: mode("gray.100", "gray.800")(props)
        },
        "#__next": {
          display: "flex"
        }
      })
    },
    colors: {
      brand: {
        25: "#fdfdff",
        50: "#ECF2FF",
        100: "#dbe6fe",
        200: "#bacefe",
        300: "#99b6fd",
        400: "#779efd",
        500: "#5686fc",
        600: "#356efb",
        700: "#1356fb",
        800: "#0446e8",
        900: "#043cc7"
      },
      brandDarkBlue: "#080f2c"
    },
    components: {
      Container: {
        baseStyle: {
          px: "32px"
        }
      },
      Button: {
        variants: {
          // eslint-disable-next-line unused-imports/no-unused-vars
          subtle: (props: StyleFunctionProps) => ({
            border: "1px solid",
            borderColor: props.colorMode === "light" ? "" : "brand.100",
            color: props.colorMode === "light" ? "brand.900" : "brand.100",
            _hover: {
              bg: mode("brand.200", "brand.50")(props),
              color: mode("light", "black")(props)
            },
            _active: {
              bg: "brand.300"
            },
            _disabled: {
              bg:
                props.colorMode === "light"
                  ? "brand.100!important"
                  : "brand.300!important",
              opacity: "0.2"
            }
          }),
          primary: (props: StyleFunctionProps) => ({
            bg: props.colorMode === "light" ? "brand.700" : "brand.200",
            color: props.colorMode === "light" ? "white" : "black",
            _hover: {
              bg: props.colorMode === "light" ? "brand.600" : "brand.100"
            },
            _active: {
              bg: "brand.500"
            },
            _disabled: {
              bg:
                props.colorMode === "light"
                  ? "brand.600!important"
                  : "brand.100!important",
              opacity: "0.2"
            }
          }),
          link: (props: StyleFunctionProps) => ({
            color: props.colorMode === "light" ? "brand.700" : "brand.200"
          }),
          ghost: (props: StyleFunctionProps) => ({
            color: props.colorMode === "light" ? "brand.600" : "gray.500"
          })
        },
        defaultProps: {
          size: "sm"
        }
      },
      Tabs: {
        variants: {
          default: (props: StyleFunctionProps) => ({
            tab: {
              color: "--chakra-colors-chakra-body-text",
              borderBottom: "solid 2px",
              borderColor: "gray.200",
              _hover: {
                color: props.colorMode === "light" ? "brand.900" : "brand.200",
                borderColor:
                  props.colorMode === "light" ? "brand.900" : "brand.200"
              },
              _selected: {
                color: props.colorMode === "light" ? "brand.900" : "brand.200",
                borderColor:
                  props.colorMode === "light" ? "brand.900" : "brand.200"
              },
              fontWeight: "bold"
            }
          })
        }
      },
      Modal: {
        defaultProps: {
          size: "lg"
        }
      },
      Tooltip: {
        baseStyle: (props: StyleFunctionProps) => ({
          bg: props.colorMode === "light" ? "gray.50" : "gray.700",
          color: props.colorMode === "light" ? "gray.800" : "gray.300"
        })
      }
    }
  },
  withDefaultColorScheme({ colorScheme: "brand" })
);

export default theme;

export const colors = theme.colors as Record<string, unknown>;
