import { Button, ListItem, Text, UnorderedList } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import MetricsTile from "./MetricsTile";

const DontGetStuckTile = ({ hide }: { hide?: () => void }) => {
  return (
    <MetricsTile title="🙋 Don't get stuck - we're here to help" hide={hide}>
      <Text pb="4">
        If you have any questions, don't hesitate to contact us. You have
        several options, you can:
      </Text>
      <UnorderedList pl="6">
        <ListItem pb="2">
          Send us an email:
          <Link to="mailto:support@enterspeed.com">
            <Button variant="link" ml="1">
              support@enterspeed.com
            </Button>
          </Link>
        </ListItem>

        <ListItem pb="2">
          Use our contact form:
          <Link
            target="_blank"
            rel="noopener noreferrer"
            to="https://www.enterspeed.com/contact"
          >
            <Button variant="link" ml="1">
              enterspeed.com/contact
            </Button>
          </Link>
        </ListItem>

        <ListItem pb="2">
          Visit the documentation:
          <Link
            target="_blank"
            rel="noopener noreferrer"
            to="https://docs.enterspeed.com"
          >
            <Button variant="link" ml="1">
              docs.enterspeed.com
            </Button>
          </Link>
        </ListItem>

        <ListItem pb="2">
          See video guides:
          <Link
            target="_blank"
            rel="noopener noreferrer"
            to="https://www.youtube.com/@enterspeedhq"
          >
            <Button variant="link" ml="1">
              youtube.com/@enterspeedhq
            </Button>
          </Link>
        </ListItem>

        <ListItem>
          Check system status:
          <Link
            target="_blank"
            rel="noopener noreferrer"
            to="https://www.enterspeedstatus.com/"
          >
            <Button variant="link" ml="1">
              enterspeedstatus.com
            </Button>
          </Link>
        </ListItem>
      </UnorderedList>
    </MetricsTile>
  );
};

export default DontGetStuckTile;
