import {
  Box,
  Button,
  Collapse,
  Flex,
  Icon,
  SimpleGrid,
  Text,
  useDisclosure
} from "@chakra-ui/react";
import { faArrowRotateRight } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { createColumnHelper, SortingState } from "@tanstack/react-table";
import { useContext, useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import BasicJsonCodeEditor from "../components/CodeEditor/BasicJsonCodeEditor";
import ContentBox from "../components/ContentBox";
import MetaTitle from "../components/MetaTitle";
import Pagination from "../components/Pagination";
import ReloadButton from "../components/ReloadButton";
import { DataTable } from "../components/table/DataTable";
import { SidePanelContext } from "../context/SidePanelContext";
import { useEnvironment } from "../features/environments/api/getEnvironment";
import useIndexMapping from "../features/indexes/api/useIndexIMapping";
import useIndexItems from "../features/indexes/api/useIndexItems";
import IndexActiveIcon from "../features/indexes/components/IndexActiveIcon";
import IndexDeployingIcon from "../features/indexes/components/IndexDeployingIcon";
import IndexItemsPanel from "../features/indexes/components/IndexItemsPanel";
import QueryErrors from "../features/indexes/components/QueryErrors";
import {
  IAndFilterGroup,
  IIndexItem,
  IOrFilterGroup,
  IQuery,
  isAndFilterCondition,
  isOrFilterCondition
} from "../features/indexes/types";
import AddFilterPopover from "../features/logs/components/AddFilterPopover";
import { queryOperators } from "../features/logs/helpers/conditionOperators";
import { createConditionId } from "../features/logs/helpers/initQueryConditionFromSearchParams";
import {
  IFilterableFieldOption,
  IFrontendCondition,
  isMultiValueFrontendCondition,
  isSingleValueFrontendCondition
} from "../features/logs/types";
import { useSchema } from "../features/schemas/api/getSchema";
import { useSourceGroups } from "../features/source-groups/api/getSourceGroups";
import { ISourceInSourceGroup } from "../features/source-groups/types";
import formatDateTime from "../helpers/formatDateTime";

const IndexPage = () => {
  const title = "Index";

  const { environmentGuid, alias, version } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const { isOpen: isQueryEditorOpen, onToggle: onToggleQueryEditor } =
    useDisclosure();
  const [selectedRow, setSelectedRow] = useState<string>("");

  const initialQuery = {
    filters: {},
    sort: [
      {
        field: "_updatedAt",
        order: "desc"
      }
    ],
    pagination: {
      page: 0,
      pageSize: 20
    }
  } as IQuery;

  const [query, setQuery] = useState<IQuery>(initialQuery);

  const [rawQuery, setRawQuery] = useState(
    JSON.stringify(initialQuery, null, "\t")
  );

  const [sorting, setSorting] = useState<SortingState>([
    {
      id: (initialQuery.sort ?? [])[0]?.field ?? "",
      desc: (initialQuery.sort ?? [])[0]?.order === "desc"
    }
  ]);

  useEffect(() => {
    query.sort = sorting.map((x) => {
      return {
        field: x.id,
        order: x.desc ? "desc" : "asc"
      };
    });

    query.pagination = {
      page: 0,
      pageSize: query.pagination?.pageSize ?? 20
    };

    const stringQuery = JSON.stringify(query, null, "\t");

    setRawQuery(stringQuery);
    setQuery(query);
  }, [sorting]);

  const [tableData, setTableData] = useState<IIndexItem[]>([]);
  const [sources, setSources] = useState<ISourceInSourceGroup[]>([]);
  const { data: sourceGroups, isLoading: isLoadingSourceGroups } =
    useSourceGroups();
  const { data: environment, isLoading: isLoadingEnvironment } =
    useEnvironment(environmentGuid);
  const { data: indexMapping, isLoading: isLoadingIndexMapping } =
    useIndexMapping({
      environmentGuid,
      schemaAlias: `${alias ?? ""}-${version ?? ""}`
    });

  useEffect(() => {
    console.log(indexMapping, isLoadingIndexMapping);
  }, [indexMapping]);

  const { data: schema, isLoading: isSchemaLoading } = useSchema({
    environmentGuid,
    alias
  });

  const currentDeployment = version
    ? schema?.deployments.find((d) => d.version === parseInt(version))
    : undefined;

  const {
    setOpen: setOpenSidePanel,
    setTitle: setSidePanelTitle,
    setContent: setSidePanelContent,
    isClosing: isClosingSidePanel
  } = useContext(SidePanelContext);

  useEffect(() => {
    if (sourceGroups) {
      const sources = sourceGroups
        .map(function (p) {
          return p.sources;
        })
        .flat();

      setSources(sources);
    }
  }, [sourceGroups]);

  const {
    data: queryResult,
    isLoading,
    refetch,
    isRefetching,
    isError,
    error
  } = useIndexItems({
    environmentGuid: environmentGuid,
    schemaAlias: `${alias ?? ""}-${version ?? ""}`,
    query: query,
    disabled: !currentDeployment
  });

  const handleOpenSidePanel = (id: string) => {
    const newSearch = new URLSearchParams(searchParams);
    newSearch.set("id", id);
    newSearch.set("sidePanel", "itemsPanel");
    setSearchParams(newSearch);
    setSelectedRow(id);
  };

  useEffect(() => {
    const newTableData =
      queryResult?.results.map((item) => ({
        id: item._id,
        _originId: item._originId,
        sourceName:
          sources.find((x) => x.source.id.sourceGuid === item._sourceGuid)
            ?.source.name ?? "",
        _updatedAt: item._updatedAt
      })) ?? [];

    if ((query.pagination?.page ?? 0) > 0) {
      setTableData(tableData.concat(newTableData));
    } else {
      setTableData(newTableData);
    }
  }, [queryResult]);

  useEffect(() => {
    const isSidePanelOpen = searchParams.get("sidePanel") === "itemsPanel";
    if (isSidePanelOpen) {
      setSidePanelTitle("Item details");
      setSidePanelContent(<IndexItemsPanel />);
      setOpenSidePanel(true);
    }
  }, [searchParams]);

  useEffect(() => {
    if (isClosingSidePanel) {
      setSelectedRow("");

      const newSearch = new URLSearchParams(searchParams);
      newSearch.delete("id");
      newSearch.delete("sidePanel");
      if (newSearch.toString() !== searchParams.toString()) {
        setSearchParams(searchParams);
      }
    }
  }, [isClosingSidePanel]);

  const columnHelper = createColumnHelper<IIndexItem>();

  const columns = [
    columnHelper.accessor("_originId", {
      cell: (info) => info.getValue(),
      header: "Origin ID",
      enableSorting: true
    }),
    columnHelper.accessor("sourceName", {
      cell: (info) => info.getValue(),
      header: "Source Name",
      enableSorting: false
    }),
    columnHelper.accessor("_updatedAt", {
      cell: (info) => formatDateTime(info.getValue()),
      header: "Updated at",
      enableSorting: true
    })
  ];

  const [filterCondition, setFilterCondition] = useState<
    IFrontendCondition | undefined
  >(undefined);

  const onClosePopover = function () {
    setFilterCondition(undefined);
  };

  const loadMore = function () {
    query.pagination ??= {};

    query.pagination = {
      page: (query.pagination.page ?? 0) + 1,
      pageSize: query.pagination.pageSize ?? 20
    };

    const stringQuery = JSON.stringify(query, null, "\t");

    setRawQuery(stringQuery);
    setQuery(query);
  };

  const onOpen = function () {
    const condition: IFrontendCondition = {
      id: createConditionId(),
      $type: "EQUAL"
    };
    setFilterCondition(condition);
  };

  const onAddFilter = function (condition: IFrontendCondition) {
    if (
      !query.filters ||
      (!isAndFilterCondition(query.filters) &&
        !isOrFilterCondition(query.filters))
    ) {
      query.filters = { and: [] };
    }

    const rootFilters =
      (query.filters as IAndFilterGroup).and ??
      (query.filters as IOrFilterGroup)?.or;

    if (isMultiValueFrontendCondition(condition)) {
      rootFilters.push({
        field: condition.field,
        operator:
          queryOperators.find((x) => x.value === condition.$type?.toString())
            ?.label ?? "",
        value: condition.values
      });
    } else if (isSingleValueFrontendCondition(condition)) {
      rootFilters.push({
        field: condition.field,
        operator:
          queryOperators.find((x) => x.value === condition.$type?.toString())
            ?.label ?? "",
        value: condition.value
      });
    } else {
      throw new Error("Something is off");
    }

    query.pagination = {
      page: 0,
      pageSize: query.pagination?.pageSize ?? 20
    };

    const stringQuery = JSON.stringify(query, null, "\t");

    setRawQuery(stringQuery);
    setQuery(query);

    setFilterCondition(undefined);
  };

  return (
    <>
      <MetaTitle
        title={`${schema?.viewHandle ?? alias ?? ""} ${title}`}
      ></MetaTitle>
      <ContentBox title={`${alias ?? ""} ${title}`} childrenMt={4}>
        <Flex fontSize="sm" color="gray.400" gap={4} mb={8}>
          <Text>
            <Text as="strong">Environment: </Text>
            {environment?.name}
          </Text>
          <Text>
            <Text as="strong">Version: </Text>
            {version}
          </Text>
          <Flex>
            <Text pr="1" as="strong">
              Status:
            </Text>
            {(() => {
              if (isSchemaLoading) {
                return (
                  <Icon
                    className="fa-spin"
                    as={FontAwesomeIcon}
                    icon={faArrowRotateRight}
                  ></Icon>
                );
              } else if (currentDeployment?.publishedAt) {
                return <IndexActiveIcon />;
              } else {
                return <IndexDeployingIcon />;
              }
            })()}
          </Flex>
        </Flex>
        <Box>
          <SimpleGrid spacing={8} columns={2} mb={isQueryEditorOpen ? 0 : 8}>
            <Flex alignItems="flex-end" gap="4">
              {!isQueryEditorOpen && (
                <Button onClick={onToggleQueryEditor} variant={"primary"}>
                  Edit as raw query
                </Button>
              )}
              {isQueryEditorOpen && (
                <Button onClick={onToggleQueryEditor} variant={"subtle"}>
                  Close raw query
                </Button>
              )}
              <AddFilterPopover
                filterFields={
                  Object.keys(indexMapping ?? {})
                    ?.map((field) => {
                      return {
                        value: field,
                        label: field,
                        inputType: "TEXT"
                      };
                    })
                    .sort((a, b) =>
                      a.label.localeCompare(b.label)
                    ) as IFilterableFieldOption[]
                }
                operators={queryOperators}
                filterCondition={filterCondition}
                onClose={onClosePopover}
                isOpen={filterCondition !== undefined}
                onOpen={onOpen}
                onAddFilter={onAddFilter}
              ></AddFilterPopover>
            </Flex>
            <Flex alignItems="flex-end">
              <ReloadButton loading={isRefetching} onClick={refetch} />
            </Flex>
          </SimpleGrid>
        </Box>

        <Collapse in={isQueryEditorOpen} animateOpacity>
          <Box mt="4">
            <BasicJsonCodeEditor
              value={rawQuery}
              options={{}}
              onValueChange={(value) => setRawQuery(value ?? "")}
            />
            <Button onClick={onToggleQueryEditor} variant={"subtle"} mr="4">
              Close raw query
            </Button>
            <Button
              onClick={() => {
                setQuery(JSON.parse(rawQuery) as IQuery);
              }}
              variant={"primary"}
              mt="4"
              mb="4"
            >
              Run query
            </Button>
          </Box>
        </Collapse>
        {isError && <QueryErrors error={error} />}
        <DataTable
          loading={
            isLoading ||
            isRefetching ||
            isLoadingSourceGroups ||
            isLoadingEnvironment
          }
          columns={columns}
          data={tableData}
          onRowClickCallback={(row: IIndexItem) => handleOpenSidePanel(row.id)}
          highlightedRow={selectedRow}
          sorting={sorting}
          setSorting={setSorting}
        />
        {queryResult && queryResult.totalResults > 0 && (
          <Pagination
            disabled={
              isLoading || tableData.length === queryResult.totalResults
            }
            current={tableData.length}
            total={queryResult.totalResults}
            loadMore={loadMore}
            loading={isLoading || isRefetching}
          />
        )}
      </ContentBox>
    </>
  );
};

export default IndexPage;
