import {
  IConditionAnd,
  ILogsState,
  InitializeAction,
  LogsAction,
  SetColumnsAction,
  SetConditionAction,
  SetDisplayTypeAction,
  SetPeriodAction,
  SetSortingDirectionAction,
  SetVisibleLogTypesAction,
  ToggleColumnAction
} from "../types";

// Reducer
export const Reducer = (state: ILogsState, action: LogsAction) => {
  switch (action.type) {
    case "initialize":
      return initializeActionHandler(state, action);
    case "set_condition":
      return setConditionActionHandler(state, action);
    case "set_period":
      return setPeriodActionHandler(state, action);
    case "set_sorting_direction":
      return setSortingDirectionActionHandler(state, action);
    case "set_columns":
      return setColumnsActionHandler(state, action);
    case "toggle_column":
      return toggleColumnActionHandler(state, action);
    case "set_display_type":
      return setDisplayTypeActionHandler(state, action);
    case "set_visible_log_types":
      return setVisibleLogTypesActionHandler(state, action);
    default:
      return state;
  }
};

// Action - Initialize
const initializeActionHandler = (
  state: ILogsState,
  { items }: InitializeAction
): ILogsState => {
  const newState: ILogsState = {
    ...state,
    items: items
  };
  return newState;
};

const setConditionActionHandler = (
  state: ILogsState,
  { value }: SetConditionAction
): ILogsState => {
  const defaultWrapper: IConditionAnd = {
    $type: "AND",
    id: "default-and",
    items: Array.isArray(value) ? [...value] : [value]
  };

  const newState: ILogsState = {
    ...state,
    condition: defaultWrapper
  };
  return newState;
};

const setPeriodActionHandler = (
  state: ILogsState,
  { value }: SetPeriodAction
): ILogsState => {
  const newState: ILogsState = {
    ...state,
    period: value
  };
  return newState;
};

const setSortingDirectionActionHandler = (
  state: ILogsState,
  { value }: SetSortingDirectionAction
): ILogsState => {
  const newState: ILogsState = {
    ...state,
    sortingDirection: value
  };
  return newState;
};

const setColumnsActionHandler = (
  state: ILogsState,
  action: SetColumnsAction
): ILogsState => {
  const newState: ILogsState = {
    ...state,
    selectedColumns: action.value
  };
  return newState;
};

const toggleColumnActionHandler = (
  state: ILogsState,
  action: ToggleColumnAction
): ILogsState => {
  const updatedColumns = [...state.selectedColumns];
  const matchingIndex = updatedColumns.findIndex((f) => f === action.value);
  if (matchingIndex >= 0) {
    updatedColumns.splice(matchingIndex, 1);
  } else {
    updatedColumns.push(action.value);
  }

  const newState: ILogsState = {
    ...state,
    selectedColumns: updatedColumns
  };
  return newState;
};

const setDisplayTypeActionHandler = (
  state: ILogsState,
  action: SetDisplayTypeAction
): ILogsState => {
  const newState: ILogsState = {
    ...state,
    displayType: action.value
  };
  return newState;
};

const setVisibleLogTypesActionHandler = (
  state: ILogsState,
  action: SetVisibleLogTypesAction
): ILogsState => {
  const propertyType = "properties.Type";
  const newState: ILogsState = {
    ...state,
    types: action.value,
    selectedColumns:
      action.value?.includes("INTERNAL") &&
      !state.selectedColumns.includes(propertyType)
        ? [propertyType, ...state.selectedColumns]
        : state.selectedColumns.filter((f) => f !== propertyType)
  };
  return newState;
};
