import { useQuery } from "@tanstack/react-query";
import { useFlags } from "launchdarkly-react-client-sdk";
import { container } from "tsyringe";
import { INGEST_REQUESTS, METRICS } from "../../../constants/queryKey";
import { QueryOptions } from "../../../types/api";
import { MetricsService } from "../metrics.service";

const queryFn = (options?: QueryOptions) => {
  const metricsService = container.resolve(MetricsService);
  return metricsService.getIngestMetrics(options);
};

const useIngestRequestsMetrics = (options?: QueryOptions) => {
  const flags = useFlags();

  const { enabled } = flags.dashboard as {
    enabled: boolean;
    features: string[];
  };
  return useQuery({
    queryKey: [METRICS, INGEST_REQUESTS, options?.tenantId],
    queryFn: () => queryFn(options),
    enabled: enabled && (flags.ingestMetric as boolean),
    refetchInterval: 61_000
  });
};

export default useIngestRequestsMetrics;
