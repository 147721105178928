import { useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { container } from "tsyringe";
import { INDEX } from "../../../constants/queryKey";
import { IndexesService } from "../../indexes/indexes.service";
import { IQuery } from "../types";

const getIndexItems = ({
  environmentGuid,
  schemaAlias,
  query
}: {
  environmentGuid?: string;
  schemaAlias?: string;
  query?: IQuery;
}) => {
  const indexesService = container.resolve(IndexesService);

  if (!environmentGuid || !schemaAlias || !query) {
    return null;
  }

  return indexesService.getIndexItems({
    environmentGuid: environmentGuid,
    schemaAlias: schemaAlias,
    query: JSON.stringify(query)
  });
};

const useIndexItems = ({
  environmentGuid,
  schemaAlias,
  query,
  disabled
}: {
  environmentGuid?: string;
  schemaAlias?: string;
  query: IQuery;
  disabled?: boolean;
}) => {
  return useQuery({
    queryKey: [INDEX, environmentGuid, schemaAlias, query],
    queryFn: () =>
      getIndexItems({
        environmentGuid: environmentGuid,
        schemaAlias: schemaAlias,
        query: query
      }),
    enabled: !!environmentGuid && !!schemaAlias && !!query && !disabled,
    retry: (failureCount, error: AxiosError) => {
      return failureCount < 3 && error.response?.status !== 400;
    }
  });
};

export default useIndexItems;
