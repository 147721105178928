import { Box } from "@chakra-ui/react";
import { Data, Layout } from "plotly.js";
import { useEffect, useRef, useState } from "react";
import Plot from "react-plotly.js";
import { useShallow } from "zustand/react/shallow";
import { useTextColors } from "../../features/tenants/hooks/colors";
import { useGeneralStore } from "../../generalStore";

const MetricsPlot = ({
  data,
  layout,
  ratio,
  isLoading
}: {
  data: Data[];
  layout?: Partial<Layout>;
  isLoading?: boolean;
  ratio?: { height: number; width: number };
}) => {
  const { fullscreen, sidePanelWidth, isSideMenuCollapsed } = useGeneralStore(
    useShallow(({ fullscreen, sidePanelWidth, isSideMenuCollapsed }) => ({
      fullscreen,
      sidePanelWidth,
      isSideMenuCollapsed
    }))
  );

  const parentRef = useRef<HTMLDivElement>(null);
  const ref = useRef<Plot>(null);
  const { text } = useTextColors();
  const [width, setWidth] = useState<number>();
  const [hasFinishedRendering, setHasFinishedRendering] = useState(false);

  ratio = ratio ?? { height: 2, width: 4 };

  const chartLayout: Partial<Layout> = {
    paper_bgcolor: "rgba(0,0,0,0)",
    plot_bgcolor: "rgba(0,0,0,0)",
    font: { color: text },
    margin: { r: 40, t: 5, l: 40, b: 5 },
    width,
    height: (ratio.height * (width ?? 0)) / ratio.width,
    ...layout,
    autosize: true
  };

  const [dimensions, setDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  });

  const handleResize = () => {
    setDimensions({
      width: window.innerWidth,
      height: window.innerHeight
    });
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize, false);
  }, []);

  useEffect(() => {
    setWidth(parentRef.current?.clientWidth);
  }, [
    dimensions,
    parentRef.current?.clientWidth,
    fullscreen,
    sidePanelWidth,
    isSideMenuCollapsed
  ]);

  useEffect(() => {
    if (parentRef.current?.clientWidth === width) {
      setHasFinishedRendering(true); // on page load the tile are jumping in sizes. This await to consecutive readings in a row to avoid jumping and flickering
    }
  }, [width]);

  return (
    <Box ref={parentRef}>
      {!isLoading && width && hasFinishedRendering && (
        <Plot
          ref={ref}
          data={data}
          layout={chartLayout}
          config={{
            displaylogo: false,
            displayModeBar: false
          }}
        ></Plot>
      )}
    </Box>
  );
};

export default MetricsPlot;
