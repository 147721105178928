import {
  Flex,
  Stat,
  StatHelpText,
  StatLabel,
  StatNumber,
  Text
} from "@chakra-ui/react";
import numeral from "numeral";
import {
  ResponseTimeMetric,
  SLAMetrics
} from "../../features/product-updates/types";
import HelpIcon from "../HelpIcon";
import MetricsTile from "./MetricsTile";
import MetricsTileBody from "./MetricsTileBody";

const DeliveryApiMetrics = ({
  sla,
  responseTimes,
  hide
}: {
  sla?: SLAMetrics;
  responseTimes?: ResponseTimeMetric[];
  hide?: () => void;
}) => {
  const current = responseTimes?.find((m) => m.region === "weu");

  return (
    <MetricsTile
      title="Delivery API"
      help="Global Delivery API metrics across all tenants for the last 90 days (updated every ~24 hours)"
      hide={hide}
    >
      <MetricsTileBody>
        <Flex
          wrap={"wrap"}
          gap={"1.5em"}
          justify={"flex-start"}
          alignItems={"flex-start"}
          align={"center"}
        >
          <Flex direction={"column"}>
            <Stat>
              <StatLabel>
                Availability
                <HelpIcon
                  label={
                    "Uptime percentage, number of incidents, and total downtime over the last 90 days"
                  }
                />
              </StatLabel>
              <StatNumber>
                {sla ? numeral(sla.availability / 100).format("0.0%") : "-"}
              </StatNumber>
              <StatHelpText>
                <Flex direction={"column"}>
                  <Text>Number of incidents: {sla?.incidentCount ?? "-"}</Text>
                  <Text>
                    Total downtime:{" "}
                    {sla ? numeral(sla.totalDownTime).format("00:00:00") : "-"}
                  </Text>
                </Flex>
              </StatHelpText>
            </Stat>
          </Flex>

          <Flex direction={"column"}>
            <Stat fontSize={"xx-large"}>
              <StatLabel>
                Server response time
                <HelpIcon
                  label={
                    "The average time over last 30 days from when the Delivery API receives the request until the request is processed on the server - excluding network latency, DNS, and SSL handshaking from this metric."
                  }
                />
              </StatLabel>
              <StatNumber>
                {current ? `${numeral(current.average).format("0.0")}ms` : "-"}
              </StatNumber>
              <StatHelpText>
                <Flex direction={"column"} wrap={"wrap"}>
                  {current?.percentiles.map((p) => (
                    <Text key={`percentile-${p.percentile}`}>
                      {numeral(p.percentile).format("0o")} percentile:{" "}
                      {`${numeral(p.value).format("0.0")}ms`}
                    </Text>
                  ))}
                </Flex>
              </StatHelpText>
            </Stat>
          </Flex>
        </Flex>
      </MetricsTileBody>
    </MetricsTile>
  );
};

export default DeliveryApiMetrics;
