import { gql, GraphQLClient } from "graphql-request";
import { inject, singleton } from "tsyringe";
import { ManagementClient } from "../../lib/management-api.client";
import { QueryOptions } from "../../types/api";
import { EnterspeedMetrics } from "../product-updates/types";

export interface SourceEntitiesMetricsDto {
  totalCount: number;
  totalSizeInBytes: number;
  sources: SourceEntityMetricsDto[];
}

export interface SourceEntityMetricsDto {
  sourceId: string;
  sourceName: string;
  count: number;
  sizeInBytes: number;
}

export interface DeliveryRequestsMetricsDto {
  deliveryApiRequests: number;
}

export interface IngestRequestsMetricsDto {
  totalRequest: number;
  ingestResults: {
    SuccessIngestResult: number;
    NoChangesIngestResult: number;
    InvalidIngestResult: number;
    PayloadTooLargeIngestResult: number;
  };
}

export interface QueuedJobsDto {
  totalJobsInBacklog: number;
  backlogPerEnvironment: { [key: string]: number };
}

@singleton()
export class MetricsService {
  constructor(
    private readonly managementClient: ManagementClient,
    @inject("managementGraphQlClient")
    private readonly managementGqlClient: GraphQLClient
  ) {}

  async getSourceEntitiesMetrics(options?: QueryOptions) {
    const { data } = await this.managementClient.get<SourceEntitiesMetricsDto>(
      "/api/v1/tenant/metrics/source-entities",
      options?.tenantId
        ? { headers: { "X-Tenant-Id": options.tenantId } }
        : undefined
    );
    return data;
  }

  async getDeliveryMetrics(options?: QueryOptions) {
    const { data } =
      await this.managementClient.get<DeliveryRequestsMetricsDto>(
        "/api/v1/tenant/metrics/delivery-requests",
        options?.tenantId
          ? { headers: { "X-Tenant-Id": options.tenantId } }
          : undefined
      );
    return data;
  }

  async getIngestMetrics(options?: QueryOptions) {
    const { data } = await this.managementClient.get<IngestRequestsMetricsDto>(
      "/api/v1/tenant/metrics/ingest-requests",
      options?.tenantId
        ? { headers: { "X-Tenant-Id": options.tenantId } }
        : undefined
    );
    return data;
  }

  async getQueuedJobsMetrics(options?: QueryOptions): Promise<QueuedJobsDto> {
    const { data } = await this.managementClient.get<QueuedJobsDto>(
      "/api/v1/tenant/metrics/processing-queue",
      options?.tenantId
        ? { headers: { "X-Tenant-Id": options.tenantId } }
        : undefined
    );
    return data;
  }

  async getEnterspeedDeliveryApiResponseTimeMetrics() {
    const request = gql`
      {
        enterspeedMetrics {
          delivery {
            responseTime(percentiles: [75, 95], unit: MS) {
              region
              average
              percentiles {
                percentile
                value
              }
            }
          }
        }
      }
    `;

    const { enterspeedMetrics } = await this.managementGqlClient.request<{
      enterspeedMetrics: EnterspeedMetrics;
    }>(request);

    return enterspeedMetrics.delivery.responseTime;
  }

  async getEnterspeedDeliveryApiSlaMetrics() {
    const request = gql`
      {
        enterspeedMetrics {
          delivery {
            sla {
              availability
              incidentCount
              totalDownTime
              averageIncidentTime
            }
          }
        }
      }
    `;

    const { enterspeedMetrics } = await this.managementGqlClient.request<{
      enterspeedMetrics: EnterspeedMetrics;
    }>(request);

    return enterspeedMetrics.delivery.sla;
  }
}
