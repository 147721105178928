import { Circle, useColorModeValue } from "@chakra-ui/react";

const NumberCircle = ({
  number,
  size
}: {
  number: string | number;
  size?: string;
}) => {
  return (
    <Circle
      size={size ?? "30px"}
      bg={useColorModeValue("gray.200", "gray.700")}
      fontSize="xs"
      fontWeight="semibold"
      userSelect="none"
    >
      {number}
    </Circle>
  );
};

export default NumberCircle;
