import { Box, Icon } from "@chakra-ui/react";
import { faTriangleExclamation } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AxiosError } from "axios";

const QueryErrors = ({ error }: { error: AxiosError }) => {
  return (
    <>
      {error && (
        <Box width="100%" color="black" backgroundColor="red.300" p={4} mb={2}>
          <Icon as={FontAwesomeIcon} icon={faTriangleExclamation} />{" "}
          {error.message}
        </Box>
      )}
    </>
  );
};
export default QueryErrors;
