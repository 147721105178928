import {
  Box,
  Flex,
  Icon,
  SimpleGrid,
  Text,
  Tooltip,
  useColorModeValue,
  useMediaQuery
} from "@chakra-ui/react";
import { faArrowRight } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import EmptyState from "../../../components/EmptyState";
import LoadingAnimation from "../../../components/LoadingAnimation";
import ReloadButton from "../../../components/ReloadButton";
import AdminTableRowId from "../../../components/table/AdminTableRowId";
import useAdminFeatures from "../../../helpers/useAdminFeatures";
import { EmptyStateType } from "../../../types/emptyState";
import { useEnvironments } from "../../environments/api/getEnvironments";
import { useSchemas } from "../../schemas/api/getSchemas";
import { ISchemaDeployment } from "../../schemas/types";

const VersionsOverview = () => {
  const [hoverElement, setHoverElement] = useState<string | null>(null);

  const [isSmallerThan900] = useMediaQuery("(max-width: 899px)");

  const navigate = useNavigate();

  const adminFeaturesEnabled = useAdminFeatures();

  const cellHeight = adminFeaturesEnabled ? "100px" : "65px";

  const {
    data: environments,
    isLoading: isEnvironmentsIsLoading,
    refetch: refetchEnvironments
  } = useEnvironments();

  const {
    data: schemas,
    isRefetching,
    refetch: refetchSchemas
  } = useSchemas({ refetch: true });

  const refetch = async () => {
    await Promise.all([refetchEnvironments(), refetchSchemas()]);
  };

  const goToSchema = (schemaId: string) => {
    navigate(`/schemas/${schemaId}`);
  };

  const HeaderActions = () => (
    <Flex justifyContent="flex-end" mb="4">
      <ReloadButton
        alignRight={false}
        loading={isRefetching}
        onClick={refetch}
      />
    </Flex>
  );

  const TableHeader = () => {
    const evenColors = useColorModeValue("gray.50", "gray.800");
    const bgColors = useColorModeValue("white", "gray.900");
    return (
      <SimpleGrid
        gridTemplateColumns={`500px repeat(${
          environments?.length ?? 0
        }, 150px)`}
        fontSize="sm"
        position="sticky"
        top={0}
        zIndex={1}
      >
        <Box
          borderBottom="1px solid"
          borderColor="gray.200"
          position="sticky"
          top={0}
          left={0}
          backgroundColor={useColorModeValue("white", "gray.900")}
          boxShadow="5px 0 5px -2px rgba(0,0,0,.05)"
        >
          {
            // Intentionally left blank
          }
        </Box>
        {environments?.map((environment) => (
          <Box
            key={`${environment.id.idValue}-header`}
            borderBottom="1px solid"
            borderColor="gray.200"
            _even={{
              backgroundColor: evenColors
            }}
            backgroundColor={bgColors}
            fontWeight="bold"
            padding="3"
            height="100%"
            textAlign="center"
            wordBreak="break-word"
          >
            {environment.name}
          </Box>
        ))}
      </SimpleGrid>
    );
  };

  const SchemaNamesColumn = () => {
    const hoverColors = useColorModeValue("gray.100", "gray.700");
    const bgColors = useColorModeValue("white", "gray.900");
    return (
      <Box
        position="sticky"
        left={0}
        backgroundColor="white"
        boxShadow="5px 0 5px -2px rgba(0,0,0,.05)"
      >
        {schemas?.map((schema) => (
          <Box
            key={schema.id.idValue}
            height={cellHeight}
            p="3"
            borderBottom="1px solid"
            borderColor="gray.200"
            onMouseEnter={() => setHoverElement(schema.id.idValue)}
            onMouseLeave={() => setHoverElement(null)}
            onClick={() => goToSchema(schema.id.mappingSchemaGuid)}
            backgroundColor={
              hoverElement === schema.id.idValue ? hoverColors : bgColors
            }
            cursor={hoverElement === schema.id.idValue ? "pointer" : "initial"}
          >
            <Box
              maxWidth="490px"
              textOverflow="ellipsis"
              overflow="hidden"
              whiteSpace="nowrap"
              fontSize="sm"
            >
              {schema.name}
            </Box>
            <Box
              fontStyle="italic"
              color="gray.500"
              maxWidth="490px"
              textOverflow="ellipsis"
              overflow="hidden"
              whiteSpace="nowrap"
              fontSize="sm"
            >
              {schema.viewHandle}
            </Box>
            <AdminTableRowId id={schema.id.idValue} />
          </Box>
        ))}
      </Box>
    );
  };

  const EnvironmentSchemaVersionsColumn = () => {
    const bgColors = useColorModeValue("gray.50", "gray.800");
    const hoverColors = useColorModeValue("gray.100", "gray.700");
    return (
      <>
        {environments?.map((environment) => (
          <Box
            key={`${environment.id.idValue}-versions`}
            _even={{
              backgroundColor: bgColors
            }}
            backgroundColor={"initial"}
          >
            {schemas?.map((schema) => {
              const deployments = schema.deployments.filter(
                (e) => e.environmentId === environment.id.idValue
              );

              // Find the most recent deployment based on the schema type and dates
              const currentDeployment = deployments.reduce(
                (latest, current) => {
                  // Check if the schema type is "partial" and compare release dates
                  if (
                    (schema.type === "partial" &&
                      (!latest ||
                        new Date(current.releasedAt) >
                          new Date(latest.releasedAt))) ||
                    // If latest is undefined, or current has a more recent published date
                    !latest ||
                    new Date(current.publishedAt ?? 0) >
                      new Date(latest?.publishedAt ?? 0)
                  ) {
                    return current; // Update latest to current
                  }

                  return latest; // Keep the current latest
                },
                undefined as ISchemaDeployment | undefined
              );

              const ongoingDeployment = deployments
                .filter(
                  (deployment) =>
                    !deployment.publishedAt && schema.type !== "partial" // Partial schemas are always considered published when released
                ) // Only ongoing deployments
                .reduce((latestOngoing, currentOngoing) => {
                  if (!currentOngoing?.releasedAt) {
                    return latestOngoing;
                  }
                  if (
                    !latestOngoing ||
                    new Date(currentOngoing.releasedAt) >
                      new Date(latestOngoing.releasedAt)
                  ) {
                    return currentOngoing;
                  }
                  return latestOngoing;
                }, undefined as ISchemaDeployment | undefined);

              return (
                <Flex
                  gap={1}
                  key={`${schema.id.idValue}-for-${environment.id.idValue}`}
                  height={cellHeight}
                  p="3"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  textAlign="center"
                  borderBottom="1px solid"
                  borderColor="gray.200"
                  fontSize="sm"
                  onMouseEnter={() => setHoverElement(schema.id.idValue)}
                  onMouseLeave={() => setHoverElement(null)}
                  onClick={() => goToSchema(schema.id.mappingSchemaGuid)}
                  backgroundColor={
                    hoverElement === schema.id.idValue ? hoverColors : "initial"
                  }
                  cursor={
                    hoverElement === schema.id.idValue ? "pointer" : "initial"
                  }
                >
                  {currentDeployment ? (
                    <Tooltip
                      isDisabled={
                        !!deployments.some(
                          (s) => s.version === schema.latestVersion
                        )
                      }
                      label={
                        <Box textAlign="center">
                          <Text>A newer version exist</Text>
                          <Text>Newest version is {schema.latestVersion}</Text>
                        </Box>
                      }
                    >
                      <Box
                        width="32px"
                        height="32px"
                        color="white"
                        backgroundColor="gray.700"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        borderRadius="full"
                        position="relative" // Add this line
                        key={`${schema.id.idValue}-for-${environment.id.idValue}`}
                      >
                        {currentDeployment.version}
                        {currentDeployment.version !== schema.latestVersion && (
                          <Box
                            width="8px"
                            height="8px"
                            bg="red.400"
                            borderRadius="full"
                            position="absolute"
                            top="0"
                            right="0"
                          />
                        )}
                      </Box>
                    </Tooltip>
                  ) : (
                    <Box
                      width="32px"
                      height="32px"
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      key={`${schema.id.idValue}-for-${environment.id.idValue}`}
                    >
                      -
                    </Box>
                  )}

                  {ongoingDeployment &&
                    ongoingDeployment !== currentDeployment && (
                      <>
                        <Icon as={FontAwesomeIcon} icon={faArrowRight}></Icon>
                        <Tooltip
                          isDisabled={!ongoingDeployment}
                          label={
                            ongoingDeployment && (
                              <Box textAlign="center">
                                <Text>Deployment in progress</Text>
                                <Text>Version {ongoingDeployment.version}</Text>
                              </Box>
                            )
                          }
                        >
                          <Box
                            className="fa-fade"
                            width="32px"
                            height="32px"
                            color="white"
                            backgroundColor="gray.700"
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            borderRadius="full"
                            key={`${schema.id.idValue}-for-${environment.id.idValue}`}
                          >
                            {ongoingDeployment.version}
                          </Box>
                        </Tooltip>
                      </>
                    )}
                </Flex>
              );
            })}
          </Box>
        ))}
      </>
    );
  };

  const TableBody = () => (
    <SimpleGrid
      gridTemplateColumns={`500px repeat(${environments?.length ?? 0}, 150px)`}
    >
      <SchemaNamesColumn />
      <EnvironmentSchemaVersionsColumn />
    </SimpleGrid>
  );

  if (isSmallerThan900) {
    return <EmptyState type={EmptyStateType.VIEW_NOT_AVAILABLE_ON_MOBILE} />;
  }

  return (
    <>
      <HeaderActions />
      {(() => {
        if (isEnvironmentsIsLoading) {
          return <LoadingAnimation />;
        } else if (environments?.length === 0) {
          return (
            <EmptyState type={EmptyStateType.NO_ENVIRONMENTS_IN_VERSIONS} />
          );
        } else if (schemas?.length === 0) {
          return (
            <Flex flexDirection="column">
              <TableHeader />
              <EmptyState type={EmptyStateType.NO_SCHEMAS_IN_VERSIONS} />
            </Flex>
          );
        } else {
          return (
            <Flex flexDirection="column">
              <TableHeader />
              <TableBody />
            </Flex>
          );
        }
      })()}
    </>
  );
};

export default VersionsOverview;
