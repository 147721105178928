export type IndexStatus = "active" | "deploying" | "notDeployed" | "removing";

export interface IIndexTableDeployment {
  status: IndexStatus;
  version?: number;
  deployedAt?: string;
}

export interface IIndexTableItem {
  id: string;
  name: string;
  environmentGuid: string;
  deployments: IIndexTableDeployment[];
}

export interface IQueryResult {
  totalResults: number;
  results: IQueryResultItem[];
}

export interface IQueryResultItem {
  _id: string;
  _originId: string;
  _sourceGuid: string;
  _updatedAt: string;
}

export interface IIndexItem {
  id: string;
  _originId: string;
  sourceName: string;
  _updatedAt: string;
}

export interface ISingleQueryItem {
  _originId: string;
  _sourceGuid: string;
  _updatedAt: string;
  [key: string]: any;
}
export interface IQuery {
  filters?: IAndFilterGroup | IOrFilterGroup;
  sort?: ISort[];
  pagination?: IPagination;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IFilter {}

export interface IAndFilterGroup extends IFilter {
  and: (IFilterCondition | IAndFilterGroup | IOrFilterGroup)[];
}

export interface IOrFilterGroup extends IFilter {
  or: (IFilterCondition | IAndFilterGroup | IOrFilterGroup)[];
}

export interface IFilterCondition extends IFilter {
  field: string;
  operator: string;
  value: any | any[];
}

export interface ISort {
  field: string;
  order?: "asc" | "desc";
}

export interface IPagination {
  page?: number;
  pageSize?: number;
}

export interface IIndexMapping {
  [key: string]: string;
}

export function isAndFilterCondition(
  object: IFilter
): object is IAndFilterGroup {
  return "and" in object;
}

export function isOrFilterCondition(object: IFilter): object is IOrFilterGroup {
  return "or" in object;
}
