import { IOperatorSelectOption } from "../types";

export const operators: IOperatorSelectOption[] = [
  {
    label: "is",
    value: "EQUALS",
    isSingleValue: true
  },
  {
    label: "is not",
    value: "NOT_EQUALS",
    isSingleValue: true
  },
  {
    label: "in",
    value: "IN",
    isSingleValue: false
  }
];

export const queryOperators: IOperatorSelectOption[] = [
  {
    label: "equals",
    value: "EQUAL",
    isSingleValue: true
  },
  {
    label: "notEquals",
    value: "NOT_EQUAL",
    isSingleValue: true
  },
  {
    label: "contains",
    value: "CONTAINS",
    isSingleValue: true
  },
  {
    label: "greaterThan",
    value: "GREATER_THAN",
    isSingleValue: true
  },
  {
    label: "greaterThanOrEquals",
    value: "GREATER_THAN_OR_EQUAL",
    isSingleValue: true
  },
  {
    label: "lessThan",
    value: "LESS_THAN",
    isSingleValue: true
  },
  {
    label: "lessThanOrEquals",
    value: "LESS_THAN_OR_EQUAL",
    isSingleValue: true
  },
  {
    label: "in",
    value: "IN",
    isSingleValue: false
  }
];
