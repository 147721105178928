import { useQuery } from "@tanstack/react-query";
import { container } from "tsyringe";
import { INDEX } from "../../../constants/queryKey";
import { IndexesService } from "../indexes.service";

const getIndexMapping = ({
  environmentGuid,
  schemaAlias
}: {
  environmentGuid?: string;
  schemaAlias?: string;
}) => {
  const indexesService = container.resolve(IndexesService);

  if (!environmentGuid || !schemaAlias) {
    return null;
  }

  return indexesService.getIndexMapping({
    environmentGuid: environmentGuid,
    schemaAlias: schemaAlias
  });
};

const useIndexMapping = ({
  environmentGuid,
  schemaAlias
}: {
  environmentGuid?: string;
  schemaAlias?: string;
}) => {
  return useQuery({
    queryKey: [INDEX, environmentGuid, schemaAlias],
    queryFn: () =>
      getIndexMapping({
        environmentGuid: environmentGuid,
        schemaAlias: schemaAlias
      }),
    enabled: !!environmentGuid && !!schemaAlias
  });
};

export default useIndexMapping;
