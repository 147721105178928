import {
  Flex,
  ListItem,
  Progress,
  Skeleton,
  Stack,
  UnorderedList
} from "@chakra-ui/react";
import { useQuery, UseQueryResult } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useShallow } from "zustand/react/shallow";
import MetricsTile from "../../components/Tiles/MetricsTile";
import {
  DATA_SOURCES,
  HAS_INGESTED_ANY_ENTITIES
} from "../../constants/queryKey";
import { getDataSourcesFn } from "../data-sources/api/getDataSources";
import { useDomains } from "../domains/api/getDomains";
import { useEnvironmentClients } from "../environment-clients/api/getEnvironmentClients";
import { useSchemas } from "../schemas/api/getSchemas";
import { getSourceEntityTypes } from "../source-entities/api/getSourceEntityTypes";
import AddEntitiesModal from "../source-entities/components/AddEntitiesModal";
import { useTenantStore } from "../tenants/store";
import { GettingStartedItem } from "./GettingStartedItem";
import { IGettingStartedStep } from "./types";

const completedStepList: IGettingStartedStep[] = [
  {
    key: "deliver",
    title: "Great job! Now it's time to fetch your content",
    description:
      "Open docs in a new pane. Integrate your Enterspeed data into your front-end project.",
    actions: [
      {
        type: "external",
        href: "https://docs.enterspeed.com/deliver#how-to-get-data-delivered",
        description: "Read more in docs"
      }
    ],
    status: "trophy"
  }
];

const GETTING_STARTED_CHECK_LIST: IGettingStartedStep[] = [
  {
    key: "tenant",
    title: "Create a new tenant",
    description: "You can think of a tenant as a property for your website.",
    status: "complete",
    actions: []
  },
  {
    key: "sources",
    title: "Create a data source",
    description:
      "Creating a data source generates an API key that lets you ingest data.",
    status: "idle",
    actions: [
      {
        href: "/settings/data-sources",
        description: "Go to data sources",
        type: "link"
      }
    ]
  },
  {
    key: "domain",
    title: "Create a domain",
    description:
      "Setting up a domain helps ensure the correct data gets fetched.",
    status: "loading",
    actions: [
      {
        href: "/settings/environment-settings#domains",
        description: "Go to domains",
        type: "link"
      }
    ]
  },
  {
    key: "environmentClient",
    title: "Create an environment client",
    description:
      "Creating an environment client generates an API key that lets you fetch data.",
    status: "loading",
    actions: [
      {
        href: "/settings/environment-settings#environment-clients",
        description: "Go to environment clients",
        type: "link"
      }
    ]
  },
  {
    key: "sourceEntity",
    title: "Ingest data to Enterspeed",
    description:
      "Add data from your current data source, so you can start transforming it.",
    status: "loading",
    actions: [
      {
        type: "modal",
        description: "Add entities",
        component: AddEntitiesModal
      },
      {
        type: "link",
        href: "/source-entities",
        description: "Go to source entities"
      }
    ]
  },
  {
    key: "schema",
    title: "Design your first schema",
    description: "Select existing data and choose how it should be structured.",
    status: "loading",
    actions: [
      {
        href: "/schemas",
        description: "Go to schemas",
        type: "link"
      }
    ]
  }
];

const GettingStartedOverview = ({ hide }: { hide?: () => void }) => {
  const { activeTenant } = useTenantStore(
    useShallow(({ activeTenant }) => ({
      activeTenant
    }))
  );

  const [steps, setSteps] = useState<IGettingStartedStep[]>(
    GETTING_STARTED_CHECK_LIST
  );
  const [isCompleted, setIsCompleted] = useState(false);

  const sources = useQuery({
    queryFn: getDataSourcesFn,
    queryKey: [DATA_SOURCES]
  });

  const hasIngestedQuery = useQuery({
    queryFn: async () =>
      sources.data && sources.data?.length > 0
        ? (
            await Promise.all(
              sources.data?.map(async (source) => {
                const types = await getSourceEntityTypes(source.id.sourceGuid);

                return !!types.length;
              })
            )
          ).some((p) => !!p)
        : false,
    enabled: !sources.isLoading,
    queryKey: [HAS_INGESTED_ANY_ENTITIES]
  });

  const environmentClients = useEnvironmentClients();
  const domains = useDomains();
  const schemas = useSchemas();

  const queries: UseQueryResult<unknown>[] = [
    sources,
    hasIngestedQuery,
    environmentClients,
    domains,
    schemas
  ];

  useEffect(() => {
    if (!sources.data) {
      return;
    }

    setSteps((steps) => {
      sources.data.length
        ? (steps[1].status = "complete")
        : (steps[1].status = "idle");
      return [...steps];
    });
  }, [sources.data]);

  useEffect(() => {
    if (!environmentClients.data) {
      return;
    }

    setSteps((steps) => {
      environmentClients.data.length
        ? (steps[3].status = "complete")
        : (steps[3].status = "idle");
      return [...steps];
    });
  }, [environmentClients.data]);

  useEffect(() => {
    if (!domains.data) {
      return;
    }

    setSteps((steps) => {
      domains.data.length
        ? (steps[2].status = "complete")
        : (steps[2].status = "idle");
      return [...steps];
    });
  }, [domains.data]);

  useEffect(() => {
    if (hasIngestedQuery.isLoading || sources.isLoading) {
      return;
    }

    setSteps((steps) => {
      hasIngestedQuery.data
        ? (steps[4].status = "complete")
        : (steps[4].status = "idle");
      return [...steps];
    });
  }, [hasIngestedQuery.data, hasIngestedQuery.isLoading, sources.isLoading]);

  useEffect(() => {
    if (!schemas.data) {
      return;
    }

    setSteps((steps) => {
      schemas.data.length
        ? (steps[5].status = "complete")
        : (steps[5].status = "idle");
      return [...steps];
    });
  }, [schemas.data]);

  const progress = steps.filter((s) => s.status === "complete").length;
  useEffect(() => {
    if (steps.every((s) => s.status === "complete")) {
      setIsCompleted(true);
    }
  }, [steps]);

  useEffect(() => {
    if (activeTenant.id) {
      setIsCompleted(false);
    }
  }, [activeTenant]);

  return (
    <MetricsTile title="🚀 Get started with Enterspeed" hide={hide}>
      {!queries.every((s) => s.isLoading) ? (
        <Flex direction={"column"} justify="space-between" flex={"1"}>
          <UnorderedList styleType="none">
            {!isCompleted
              ? steps.map((step) => (
                  <ListItem key={step.key}>
                    <GettingStartedItem step={step}></GettingStartedItem>
                  </ListItem>
                ))
              : completedStepList.map((step) => (
                  <ListItem key={step.key}>
                    <GettingStartedItem step={step}></GettingStartedItem>
                  </ListItem>
                ))}
          </UnorderedList>

          <Progress
            value={(progress / steps.length) * 100}
            size={"md"}
            borderRadius={"6px"}
            colorScheme={progress === steps.length ? "green" : "blue"}
          ></Progress>
        </Flex>
      ) : (
        <Stack>
          <Skeleton height="20px" />
          <Skeleton height="20px" />
          <Skeleton height="20px" />
          <Skeleton height="20px" />
          <Skeleton height="20px" />
          <Skeleton height="20px" />
        </Stack>
      )}
    </MetricsTile>
  );
};

export default GettingStartedOverview;
