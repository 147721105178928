import { create } from "zustand";
import useMixPanel from "../../mixpanel/useMixPanel";

type IngestApiRequestStatsTileVariations = "total" | "changed" | "all";

interface IngestApiRequestsStore {
  selectedVersion: IngestApiRequestStatsTileVariations;
  selectVersion: (key: IngestApiRequestStatsTileVariations) => void;
}

const useIngestApiRequestsStore = () => {
  const storageKey = "ingest-api-requests-storage";
  const mixpanel = useMixPanel();
  const stored = localStorage.getItem(storageKey);
  const initialState = stored
    ? (JSON.parse(stored) as IngestApiRequestsStore)
    : {
        selectedVersion: "total" as IngestApiRequestStatsTileVariations,
        selectVersion: () => {
          //
        }
      };

  return create<IngestApiRequestsStore>((set) => ({
    ...initialState,
    selectVersion: (key) => {
      set((state) => {
        if (state.selectedVersion === key) {
          return state;
        }

        const newState = {
          ...state,
          selectedVersion: key
        };
        localStorage.setItem(storageKey, JSON.stringify(newState));
        return newState;
      });
      mixpanel.track(`Seletect '${key}' ingest API request version`);
    }
  }));
};

export default useIngestApiRequestsStore;
